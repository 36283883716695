// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-theme-dox-src-templates-doc-js": () => import("./../../../node_modules/gatsby-theme-dox/src/templates/doc.js" /* webpackChunkName: "component---node-modules-gatsby-theme-dox-src-templates-doc-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-package-reactjs-js": () => import("./../../../src/pages/package/reactjs.js" /* webpackChunkName: "component---src-pages-package-reactjs-js" */)
}

